<template>
  <div>
    <app-input-search
      v-model="search"
      placeholder="Поиск"
      :style="'width:' + (isFocus ? focusWidth : width) + 'px'"
      style="height: 25px; font-size: 13px"
      @enter="pressEnter"
      @focus="isFocus = true; numLoad = 1"
      @focusout="isFocus = false"
      @keydown="selectedAddress($event)"
    />

    <datalist v-if="searchList.length > 0 && numLoad > 0" id="search-list">
      <option v-for="search in searchList" :key="search.place_id" @click="selectOpt(search)">{{ search.description }}</option>
    </datalist>
  </div>
</template>

<script>
import {getPlace, searchAddress} from '../../api/SearchAddress.js';

export default {
  name: 'AppSearchAddress',
  props: {
    width: {
      type: Number,
      default: 150,
    },

    focusWidth: {
      type: Number,
      default: 150,
    },
  },
  emits: ['selected-address'],

  data() {
    return {
      isFocus: false,
      search: '',
      searchList: [],
      isSelectedAddress: false,
      numLoad: 0,
    };
  },

  watch: {
    async search() {
      if (!this.isSelectedAddress) {
        await searchAddress(this.search)
            .then((response) => {
              this.searchList = response.data.response.predictions;
              this.numLoad++;
            });
      } else {
        this.isSelectedAddress = false;
        const selectedPlaceId = this.searchList.filter((value) => value.description === this.search)[0].place_id;
        await getPlace(selectedPlaceId)
            .then((response) => {
              this.$emit('selected-address', response.data.response.result.geometry.location);
            });
      }
    },
  },

  methods: {
    async selectedAddress(e) {
      if (!e.key) {
        const self = this;
        this.isSelectedAddress = true;
        setTimeout(() => {
          self.searchList = [];
          self.isSelectedAddress = false;
        }, 100);
      }
    },

    pressEnter() {
      const inputValue = this.search.trim().replace(', ', ' ').replace(/^\s$/, ' ').replace(',', '.');
      if (/^[+-]?([0-9]*[.])?[0-9]+\s+?[+-]?([0-9]*[.])?[0-9]+$/.test(inputValue)) {
        const latitude = parseFloat(inputValue.split(/\s+/)[0]);
        const longitude = parseFloat(inputValue.split(/\s+/)[1]);
        this.$emit('selected-address', {lat: latitude, lng: longitude});
      }
    },

    selectOpt(item) {
      this.isSelectedAddress = true;
      this.search = item.description;
      this.numLoad = -1;
    },
  },
};
</script>

<style scoped>
datalist {
  left: 0px;
  background: white !important;
  display: block;
  border: 1px solid #C6CDD3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}

option {
  padding: 4px;
}

option:hover {
  background: #DFEAFF;
}

</style>
