import {createApp} from 'vue';
import HighchartsVue from 'highcharts-vue';
import Viewer from 'v-viewer';
import axios from 'axios';
import store from './store';
import components from './components';
import router from './router';
import App from './App.vue';
import {get, post, postFile, restFullDelete} from './utils/axios.plugin';
import alert from './utils/alert';
import {surveyPlugin} from 'survey-vue3-ui'
import {setupCalendar} from 'v-calendar';
import validatorPlugin from '@/utils/validator.js';
import * as Sentry from '@sentry/vue';

const app = createApp(App);
app.config.productionTip = false;

//app.config.errorHandler = (error) => console.log(error);
app.config.globalProperties.$alert = alert;
app.config.globalProperties.$get = get;
app.config.globalProperties.$post = post;
app.config.globalProperties.$postFile = postFile;
app.config.globalProperties.$restFullDelete = restFullDelete;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$validator = validatorPlugin;

components.forEach((component) => {
    app.component(component.name, component);
});

Sentry.init({
    app,
    dsn: 'https://9ff6b664e6d73832d53bb95b3ae24e0f@sentry.nav.by/2', // Replace with your Sentry DSN
    release: '25-01-24-20:02-nikolaywaluy',
    beforeSend(event) {
        if (event.metadata && event.metadata.title) {
            // Use the title from metadata as the grouping fingerprint
            event.fingerprint = [event.metadata.title];
        } else {
            // Fallback to default grouping if title is unavailable
            event.fingerprint = ['{{ default }}'];
        }
        return event;
    },
});

app
    .use(store)
    .use(router)
    .use(Viewer)
    .use(HighchartsVue)
    .use(surveyPlugin)
    .use(setupCalendar, {})
    .mount('#app');


