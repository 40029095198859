<template>
  <div class="d-flex" :class="{'with-login': withLogin || withResetButton}">
    <app-select
      id="user_id"
      track-by="user_id"
      :options="options"
      :searchable="true"
      :width="width"
      :loading="isLoadingUsers"
      :placeholder="placeholder"
      :value="selectedUser"
      label="name"
      @changeAction="$emit('changeAction', $event ? $event.user_id : null)"
      @updateSearch="updateSearchSelect"
    >
      <template #noOptions>Введите название клиента или ID</template>
    </app-select>

    <div v-if="withLogin" class="login-button" @click="login">
      <img src="https://images.nav.by/img/cdn/admin/log-in.svg" style="vertical-align: text-top;">
    </div>

    <div v-if="withResetButton" class="login-button" @click="clearValue">
      <img
        src="https://images.nav.by/img/cdn/system/dialog_close_button.svg"
        style="vertical-align: text-top; width: 25px;"
      >
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AppSelectUsers',
  props: {
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    width: {
      type: String,
      default: null,
    },
    value: {
      required: false,
    },
    searchByAllUsers: {
      type: Boolean,
      default: false,
    },
    withLogin: {
      type: Boolean,
      default: false,
    },
    withResetButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeAction'],
  data() {
    return {
      isLoadingUsers: false,
      options: [],
      selectOption: null,
      selectedUser: null,
    };
  },
  watch: {
    value() {
      if (this.value) {
        this.applyUser(this.value);
      } else {
        this.options = [];
        this.selectedUser = null;
      }
    },
  },
  created() {
    if (this.value) {
      this.applyUser(this.value);
    }
  },
  methods: {
    updateSearchSelect: _.debounce(function (searchQuery) {
      this.getUsers(searchQuery).then();
    }, 500),

    async getUsers(search) {
      if (search) {
        this.isLoadingUsers = true;
        await this.$get(`/lumen/system_login/${this.searchByAllUsers ? 'search_by_all_users' : 'users'}`, {
          search,
        }).then((response) => {
          if (response.data.exitCode === 0) {
            this.options = response.data.users.map((user) => ({
              name: `${user.user_id} ${user.name} (${user.managerName})`,
              user_id: user.user_id
            }));
          } else if (response.data.exitCode === 2) {
            this.$alert.showErrorMessage('Лимит превышен');
          }
          this.isLoadingUsers = false;
        });
      }
    },

    applyUser(userId) {
      const self = this;
      this.getUsers(userId).then(() => {
        self.options.forEach((option) => {
          if (parseInt(userId, 10) === parseInt(option.user_id, 10)) {
            self.selectedUser = option;
          }
        });
      });
    },

    login() {
      if (!this.selectedUser) {
        this.$alert.showErrorMessage('Выберите клиента');
        return;
      }
      window.open(`https://adm.nav.by/lumen/open_client/open?user_id=${this.selectedUser.user_id}`);
    },

    clearValue() {
      this.$emit('changeAction', null);
    },
  },
};
</script>

<style>
.login-button {
  border: 1px solid #C6CDD3;
  border-radius: 0 5px 5px 0;
  vertical-align: center;
  padding: 0 3px;
  border-left: none;
  cursor: pointer;
}

.with-login .multiselect__tags {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
