import Cookies from 'vue-cookies';
import alert from '../utils/alert.js';
import {get} from '../utils/axios.plugin';

export default {
  actions: {
    async login({commit}, {login, password}) {
      await get('/lumen/auth/login', {
          login,
          password,
      })
        .then((response) => {
          if (response.data.exitCode === 0) {
            localStorage.setItem('firebaseMessagingToken', null);
            commit('setUserInfo', response.data.userInfo);
            Cookies.set('token', response.data.token, 60 * 60 * 24 * 30 * 12);
            return Promise.resolve()
          }
          alert.showErrorMessage('Неверный логин или пароль');
          return Promise.reject();
        });
    },
    async logout({commit}) {
      await get('/lumen/auth/logout', {})
        .then((response) => {
          if (response.data.exitCode === 0) {
            Cookies.remove('token');
            commit('clearUserInfo');
            commit('clearPagesInfo');
            return Promise.resolve();
          }
          alert.showErrorMessage('Ошибка');
          return Promise.reject();
        });
    },
  },
};
